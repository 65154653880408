@import '../../assets/css/common.scss';

.artifact_tile {
    display: flex;
    flex-direction: column;
    margin: 0;
    border: 1px solid #D5D8DE;
    padding: 7px;
    border-radius: 8px;
    height: 88px;
    width: 150px;
    flex: 0 0 150px;

    &:hover {
        background: var(--neutral20);
    }

    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        overflow: hidden;
        &, * { font-size: 12px; }
    }

    .head {
        margin-top: 12px;
        line-height: 16px;
        overflow: hidden;
        text-overflow: ellipsis;

        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}