.wrapper {
    display: inline-flex;
    .input {
        display: none;
    }
    .label {
        font-size: 15px;
        line-height: 24px;
        height: 24px;
        padding-left: 34px;
        position: relative;
        &:hover {
            cursor: pointer;
        }
        &::before {
            content: '';
            position: absolute;
            width: 24px;
            height: 24px;
            left: 0;
            top: 0;
            border-radius: 12px;
            border: 1px solid var(--gray-100)
        }
    }
    &.wrapper_active {
        .label {
            &::before {
                border: none;
                background: var(--primary60);
            }
            &::after {
                content: '';
                position: absolute;
                width: 12px;
                height: 12px;
                left: 6px;
                top: 6px;
                border-radius: 6px;
                background: #fff;
            }
        }
    }
}